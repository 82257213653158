import {
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING,
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS,
  DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
  DELETE_EVIDENCE_ATTACHMENTS_LOADING,
  DELETE_EVIDENCE_ATTACHMENTS_SUCCESS,
  DELETE_EVIDENCE_DOCUMENTS_FAILURE,
  DELETE_EVIDENCE_DOCUMENTS_LOADING,
  DELETE_EVIDENCE_DOCUMENTS_SUCCESS,
  EDITED_QUESTION_INDEX_SET,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_LOADING,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_SUCCESS,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_LOADING,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_SUCCESS,
  RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE,
  RESET_ASSESSMENT_QUESTIONNAIRE_LOADING,
  RESET_ASSESSMENT_QUESTIONNAIRE_SUCCESS,
  RESET_EVIDENCE_FLAG,
  RESET_REQUIREMENTS_LIST,
  SEARCH_ASSESSMENT_BY_ID_FAILURE,
  SEARCH_ASSESSMENT_BY_ID_LOADING,
  SEARCH_ASSESSMENT_BY_ID_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_LOADING,
  SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS,
  SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE,
  SEARCH_CO_PILOT_GENERATED_RESPONSES_LOADING,
  SEARCH_CO_PILOT_GENERATED_RESPONSES_SUCCESS,
  SEARCH_EVIDENCE_DETAILS_FAILURE,
  SEARCH_EVIDENCE_DETAILS_LOADING,
  SEARCH_EVIDENCE_DETAILS_SUCCESS,
  SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE,
  SEARCH_SHOW_ME_REDIRECTION_MODAL_LOADING,
  SEARCH_SHOW_ME_REDIRECTION_MODAL_SUCCESS,
  SET_ANSWER_COMPONENT_TAB,
  SET_COMMENTED_VALUE_ON_BLUR,
  SET_COPILOT_RESPONSE_FOR_SELECTED_DRU,
  SET_EXPANDED_CO_PILOT_SECTIONS,
  SET_LOADING_EXPANDED_SECTIONS,
  SET_REQUIREMENT_LIST,
  SET_REQUIREMENT_QUESTIONS_TAB,
  SET_REQUIREMENT_SUMMARY_TAB,
  SET_REQUIREMENT_SUMMARY_TYPE_TAB,
  SET_RESPONSE_SAVED_STATUS,
  SET_SELECTED_ASSESSMENT_QUESTIONNAIRE,
  SET_SELECTED_CARD,
  SET_SELECTED_REQUIREMENT,
  SET_SELECTED_REQUIREMENT_ID,
  SET_SELECTED_REQUIREMENT_QUESTIONS,
  SET_SHOW_ME_REDIRECTION_MODAL,
  SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
  SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING,
  SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
  SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE,
  SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_LOADING,
  SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_SUCCESS,
  SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_LOADING,
  SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_SUCCESS,
  SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_FAILURE,
  SET_CURRENT_QUESTIONNAIRE_INDEX,
  SET_SELECTED_DOCUMENT_LIST,
  RESET_LOCAL_FORM_DATA
} from 'actions/assessmentQuestionnaire';
import { DELETED_SUCCESSFULLY, ERROR_MESSAGE, SAVED_SUCCESSFULLY, SUBMITTED_SUCCESSFULLY, UPLOADED_SUCCESSFULLY } from 'constants/errorMessage';
import { YES_VALUE } from 'constants/constants';
import { isEmpty, isNil, some, sortBy } from 'lodash';
import {
  ANSWERED_QUESTION_RESPONSE_CATEGORY_ID,
  ANSWER_COMPONENT_TAB_OPTIONS,
  CHILD_MODE,
  COPILOT_QUESTION_RESPONSE_MODE_ID,
  DATE_TYPE_RESPONSE_ID,
  FILE_UPLOAD_RESPONSE_ID,
  MULTI_SELECT_RESPONSE_ID,
  NO_RESPONSE_TEXT,
  ORG_CANNOT_UPLOAD_FILE,
  PARENT_MODE,
  REQUIREMENTS_SUMMARY_TAB_OPTIONS,
  REQUIREMENT_QUESTIONS_TAB_OPTIONS,
  REVIEW_SUMMARY_TAB_OPTIONS,
  SINGLE_SELECT_RESPONSE_ID,
  UNANSWERED_QUESTION_RESPONSE_CATEGORY_ID,
} from 'pages/AssessmentQuestionnaire/constants';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  loadingExpandedSections: false,
  requirementQuestionsTabValue: REQUIREMENT_QUESTIONS_TAB_OPTIONS.ALL,
  answerComponentTabValue: ANSWER_COMPONENT_TAB_OPTIONS.ALL,
  requirementList: [],
  unansweredRequirements: [],
  selectedRequirement: {},
  selectedRequirementQuestions: [],
  selectedCard: {},
  evidenceList: [],
  answersList: {},
  commentedValue: null,
  selectedAssessmentQuestionnaire: {},
  showSummaryScreen: false,
  selectedRequirementId: null,
  autoSaveLoading: false,
  showMeRedirectionModal: {},
  coPilotResponse: {},
  selectedAssessment: {},
  expandedCoPilotSectionId: false,
  coPilotResponseLoading: false,
  forceSaveLoading: false,
  requirementSummaryTabValue: REQUIREMENTS_SUMMARY_TAB_OPTIONS.GRID,
  requirementSummaryTypeTabValue: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
  answeredRequirements: [],
  resetQuestionnaireLoading: false,
  openRedirectionModal: false,
  uploadedList: [],
  evidence: null,
  editedQuestionIndexSet: [],
  currentQuestionnaireIndex: null,
  selectedDocumentList: null,
  localFormData: null,
};

const getResponse = (responseText, responseTypeId) => {
  if (responseTypeId === DATE_TYPE_RESPONSE_ID && responseText === NO_RESPONSE_TEXT) return null;
  if (responseTypeId === MULTI_SELECT_RESPONSE_ID) return !isNil(responseText) ? sortBy(responseText.split('|')) : []
  return responseText;
}

const getUnanswerResponse = (responseTypeId, questionData, isChildQuestion = false) => {
  const options = isChildQuestion ? questionData?.QuestionResponseOptionList : questionData?.ResponseOptionList;
  
  if (!some(options, { IsDefault: YES_VALUE })) return responseTypeId === MULTI_SELECT_RESPONSE_ID ? [] : null;

  const defaultOption = options?.find((item) => item?.IsDefault === YES_VALUE);
  return getResponse(defaultOption?.ResponseOptionText, responseTypeId);
}

export const getSavedAnswers = (data, localValues, coPilotOnDRUIndex = null, mode = '') => {
  let savedAnswers = {};
  data.forEach((obj, index) => {
    if (!isEmpty(mode) && mode === PARENT_MODE && !isNil(coPilotOnDRUIndex) && coPilotOnDRUIndex === index) {
      if (!isEmpty(obj?.AiGeneratedResponseText)) {
        obj.QuestionResponseText = obj.AiGeneratedResponseText;
        obj.QuestionResponseComment = obj.AiGeneratedResponseComment;
        obj.QuestionResponseModeId = COPILOT_QUESTION_RESPONSE_MODE_ID;
      }
    }

    const questionResponseText = obj['QuestionResponseText'];
    const questionResponseTypeId = obj['QuestionResponseTypeId'];

    // Get answered response
    if (!isEmpty(questionResponseText)) {
      savedAnswers[`QuestionResponseText${index}`] = localValues?.[`QuestionResponseText${index}`] || getResponse(questionResponseText, questionResponseTypeId);
    }
    // Unanswered response - get response from default option 
    else if (questionResponseTypeId === SINGLE_SELECT_RESPONSE_ID || questionResponseTypeId === MULTI_SELECT_RESPONSE_ID) {
      const defaultResponse = getUnanswerResponse(questionResponseTypeId, obj, false);
      if (!isNil(defaultResponse)) savedAnswers[`QuestionResponseText${index}`] = defaultResponse;
    }

    if (!isEmpty(obj['QuestionResponseComment'])) {
      savedAnswers[`QuestionResponseComment${index}`] = localValues?.[`QuestionResponseComment${index}`] || obj['QuestionResponseComment'];
    }
    if (!isNil(obj['CanOrgUploadEvidence']) && (obj['AllowFileUpload'] === YES_VALUE || questionResponseTypeId === FILE_UPLOAD_RESPONSE_ID)) {
      savedAnswers[`CanOrgUploadEvidence${index}`] = (localValues?.[`CanOrgUploadEvidence${index}`] || obj['CanOrgUploadEvidence']) === YES_VALUE ? ORG_CANNOT_UPLOAD_FILE[0]?.ResponseOptionText.split('|') : [];
    }
    if (questionResponseTypeId === SINGLE_SELECT_RESPONSE_ID || questionResponseTypeId === MULTI_SELECT_RESPONSE_ID) {
      obj.ResponseOptionList.forEach((responseOptionItem, responseOptionIndex) => {
        responseOptionItem.QuestionLogicRuleList.length > 0 &&
          responseOptionItem.QuestionLogicRuleList[0].ChildQuestionList.map((childResponse, childIndex) => {
            if (!isEmpty(mode) && mode === CHILD_MODE && !isNil(coPilotOnDRUIndex) && coPilotOnDRUIndex === index) {
              if (!isEmpty(childResponse?.AiGeneratedResponseText)) {
                childResponse.QuestionResponseText = childResponse.AiGeneratedResponseText;
                childResponse.QuestionResponseComment = childResponse.AiGeneratedResponseComment;
                childResponse.QuestionResponseModeId = COPILOT_QUESTION_RESPONSE_MODE_ID;
              }
            }

            const childQuestionResponseTypeId = childResponse['QuestionResponseTypeId'];
            const childQuestionResponseText = childResponse['QuestionResponseText'];

            if (!isEmpty(childQuestionResponseText)) {
              savedAnswers[`QuestionResponseText${index}_${responseOptionIndex}_${childIndex}`] = localValues?.[`QuestionResponseText${index}_${responseOptionIndex}_${childIndex}`] || getResponse(childQuestionResponseText, childQuestionResponseTypeId);
            } else if (
              childQuestionResponseTypeId === SINGLE_SELECT_RESPONSE_ID ||
              childQuestionResponseTypeId === MULTI_SELECT_RESPONSE_ID
            ) {
              const defaultChildResponse = getUnanswerResponse(childQuestionResponseTypeId, childResponse, true);
              if (!isNil(defaultChildResponse)) savedAnswers[`QuestionResponseText${index}_${responseOptionIndex}_${childIndex}`] = defaultChildResponse;
            } 

            if (!isEmpty(childResponse['QuestionResponseComment'])) {
              savedAnswers[`QuestionResponseComment${index}_${responseOptionIndex}_${childIndex}`] = localValues?.[`QuestionResponseComment${index}_${responseOptionIndex}_${childIndex}`] || childResponse['QuestionResponseComment'];
            }
            if (!isNil(childResponse['CanOrgUploadEvidence']) && (childResponse['AllowFileUpload'] === YES_VALUE || childQuestionResponseTypeId === FILE_UPLOAD_RESPONSE_ID)) {
              savedAnswers[`CanOrgUploadEvidence${index}_${responseOptionIndex}_${childIndex}`] = (localValues?.[`CanOrgUploadEvidence${index}_${responseOptionIndex}_${childIndex}`] || childResponse['CanOrgUploadEvidence']) === YES_VALUE ? ORG_CANNOT_UPLOAD_FILE[0]?.ResponseOptionText.split('|') : [];
            }
          });
      });
    }
  });

  return savedAnswers;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUIREMENT_QUESTIONS_TAB: {
      return {
        ...state,
        requirementQuestionsTabValue: payload,
      };
    }

    case SET_ANSWER_COMPONENT_TAB: {
      return {
        ...state,
        answerComponentTabValue: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING: {
      return {
        ...state,
        requirementLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: payload,
        unansweredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers !== item.CountOfParentQuestions) : [],
        answeredRequirements: Array(payload) ? payload.filter((item) => item.CountOfParentQuestionAnswers === item.CountOfParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: [],
        unansweredRequirements: [],
        answeredRequirements: [],
      };
    }

    case SET_SELECTED_REQUIREMENT: {
      const { showSummaryScreen } = action;
      return {
        ...state,
        selectedRequirement: payload,
        showSummaryScreen: showSummaryScreen,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_LOADING: {
      return {
        ...state,
        questionLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_SUCCESS: {
      let questions = [];
      let unansweredQuestions = [];
      let answeredQuestions = [];

      payload.forEach((obj, index) => {
        if (obj.QuestionResponseCategoryId === UNANSWERED_QUESTION_RESPONSE_CATEGORY_ID) {
          unansweredQuestions.push({ ...obj, index: index, ResponseOptionList: obj?.ResponseOptionList ? obj?.ResponseOptionList : [] });
        } else if (obj.QuestionResponseCategoryId === ANSWERED_QUESTION_RESPONSE_CATEGORY_ID) {
          answeredQuestions.push({ ...obj, index: index, ResponseOptionList: obj?.ResponseOptionList ? obj?.ResponseOptionList : [] });
        }
        questions.push({ ...obj, index: index, ResponseOptionList: obj?.ResponseOptionList ? obj?.ResponseOptionList : [] });

      });

      return {
        ...state,
        questionLoading: false,
        selectedRequirementQuestions: questions,
        answersList: getSavedAnswers(questions),
        unansweredQuestions: unansweredQuestions,
        answeredQuestions: answeredQuestions,
        uploadedList: questions,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_FAILURE: {
      return {
        ...state,
        questionLoading: false,
        selectedRequirementQuestions: [],
        answersList: {},
        unansweredQuestions: [],
        answeredQuestions: [],
        uploadedList: [],
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_LOADING: {
      return {
        ...state,
        autoSaveLoading: true,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_SUCCESS: {
      return {
        ...state,
        autoSaveLoading: false,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        autoSaveLoading: false,
      };
    }

    case SET_SELECTED_CARD: {
      return {
        ...state,
        loading: false,
        selectedCard: payload,
      };
    }

    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS: {
      const { evidence = null } = action;
      toast.success(UPLOADED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        evidence: evidence,
      };
    }
    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        evidence: null,
      };
    }

    case SEARCH_EVIDENCE_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_EVIDENCE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        evidenceList: payload,
      };
    }
    case SEARCH_EVIDENCE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        evidenceList: [],
      };
    }

    case DELETE_EVIDENCE_DOCUMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_EVIDENCE_DOCUMENTS_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_EVIDENCE_DOCUMENTS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_COMMENTED_VALUE_ON_BLUR: {
      return {
        ...state,
        commentedValue: payload,
      };
    }

    case SET_SELECTED_ASSESSMENT_QUESTIONNAIRE: {
      return {
        ...state,
        selectedAssessmentQuestionnaire: payload,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_SUCCESS: {
      const { evidence = null } = action;
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        evidence: evidence,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        evidence: null
      };
    }

    case SET_LOADING_EXPANDED_SECTIONS: {
      return {
        ...state,
        loadingExpandedSections: payload,
      };
    }

    case SET_SELECTED_REQUIREMENT_ID: {
      return {
        ...state,
        selectedRequirementId: payload,
      };
    }

    case SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS: {
      toast.success(SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case SUBMIT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_RESPONSE_SAVED_STATUS: {
      return {
        ...state,
        autoSaveLoading: payload,
      };
    }

    case SET_REQUIREMENT_LIST: {
      return {
        ...state,
        requirementList: payload,
      };
    }
    case SEARCH_SHOW_ME_REDIRECTION_MODAL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SHOW_ME_REDIRECTION_MODAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        showMeRedirectionModal: payload,
        openRedirectionModal: true,
      };
    }
    case SEARCH_SHOW_ME_REDIRECTION_MODAL_FAILURE: {
      return {
        ...state,
        loading: false,
        showMeRedirectionModal: payload,
        openRedirectionModal: true,
      };
    }
    case SET_SHOW_ME_REDIRECTION_MODAL: {
      return {
        ...state,
        showMeRedirectionModal: {},
        openRedirectionModal: false,
      };
    }
    case SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SUBMIT_ASSESSMENT_REDIRECTION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_CO_PILOT_GENERATED_RESPONSES_LOADING: {
      return {
        ...state,
        coPilotResponseLoading: true,
      };
    }

    case SEARCH_CO_PILOT_GENERATED_RESPONSES_SUCCESS: {
      let genereatedAnswers = {
        ...payload,
        QuestionResponseText: !isEmpty(payload?.QuestionResponseText) ? payload?.QuestionResponseText.split('|') : [],
        DocumentList: !isEmpty(payload?.DocumentList[0]) ? payload?.DocumentList[0]?.split('|') : [],
      };
      return {
        ...state,
        coPilotResponseLoading: false,
        coPilotResponse: genereatedAnswers,
      };
    }

    case SEARCH_CO_PILOT_GENERATED_RESPONSES_FAILURE: {
      return {
        ...state,
        coPilotResponseLoading: false,
        coPilotResponse: {},
      };
    }

    case SET_EXPANDED_CO_PILOT_SECTIONS: {
      return {
        ...state,
        loading: false,
        expandedCoPilotSectionId: isNil(payload) || payload === state?.expandedCoPilotSectionId ? false : payload,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedAssessment: payload,
      };
    }

    case SEARCH_ASSESSMENT_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedAssessment: {},
      };
    }

    case SET_COPILOT_RESPONSE_FOR_SELECTED_DRU: {
      const { index = null, questions = [], mode = '' } = payload;
      return {
        ...state,
        answersList: getSavedAnswers(questions, {}, index, mode),
      };
    }

    case RESET_ASSESSMENT_QUESTIONNAIRE_LOADING: {
      return {
        ...state,
        resetQuestionnaireLoading: true,
      };
    }

    case RESET_ASSESSMENT_QUESTIONNAIRE_FAILURE: {
      return {
        ...state,
        resetQuestionnaireLoading: false,
      };
    }

    case RESET_ASSESSMENT_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        resetQuestionnaireLoading: false,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_LOADING: {
      return {
        ...state,
        forceSaveLoading: true,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_SUCCESS: {
      const { showToaster } = action;
      showToaster ? toast.success(SAVED_SUCCESSFULLY) : null;
      return {
        ...state,
        forceSaveLoading: false,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_RESPONSES_FORCE_SAVE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        forceSaveLoading: false,
      };
    }

    case SET_SELECTED_REQUIREMENT_QUESTIONS: {
      return {
        ...state,
        selectedRequirementQuestions: payload,
      };
    }

    case SET_REQUIREMENT_SUMMARY_TAB: {
      return {
        ...state,
        requirementSummaryTabValue: payload,
      };
    }

    case SET_REQUIREMENT_SUMMARY_TYPE_TAB: {
      return {
        ...state,
        requirementSummaryTypeTabValue: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING: {
      return {
        ...state,
        requirementLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS: {

      let list = [];
      payload.forEach((obj) => {
        list.push({
          ...obj,
          RequirementName: obj?.RequirementGroupName,
          CountOfParentQuestionAnswers: obj?.CountOfRequirementGroupParentQuestionAnswers,
          CountOfParentQuestions: obj?.CountOfRequirementGroupParentQuestions,
          isRequirementGroup: YES_VALUE,
        });
      });

      return {
        ...state,
        requirementLoading: false,
        requirementList: list,
        unansweredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers !== item.CountOfRequirementGroupParentQuestions) : [],
        answeredRequirements: Array(list) ? list.filter((item) => item.CountOfRequirementGroupParentQuestionAnswers === item.CountOfRequirementGroupParentQuestions) : [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE: {
      return {
        ...state,
        requirementLoading: false,
        requirementList: [],
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_SUCCESS: {

      return {
        ...state,
        loading: false,
        uploadedList: payload,
      };
    }

    case SEARCH_ASSESSMENT_QUESTIONNAIRE_RESPONSE_INITIALIZE_FORM_FAILURE: {
      return {
        ...state,
        loading: false,
        uploadedList: [],
      };
    }

    case RESET_EVIDENCE_FLAG: {
      return {
        ...state,
        evidence: null,
      };
    }

    case EDITED_QUESTION_INDEX_SET: {
      let set = [];
      if (!isNil(payload)) {
        set = [...state.editedQuestionIndexSet, payload];
      }

      return {
        ...state,
        editedQuestionIndexSet: set,
      };
    }

    case RESET_REQUIREMENTS_LIST: {
      return {
        ...state,
        requirementList: [],
        unansweredRequirements: [],
        answeredRequirements: [],
        selectedAssessmentQuestionnaire: {},
      };
    }

    case SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_LOADING: {
      return {
        ...state,
        entireQuesLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_SUCCESS: {
      return {
        ...state,
        entireQuesLoading: false,
        entireQuestionnaire: payload,
      };
    }

    case SEARCH_ASSESSMENT_ENTIRE_QUESTIONNAIRE_RESPONSE_FAILURE: {
      return {
        ...state,
        entireQuesLoading: false,
        entireQuestionnaire: []
      };
    }

    case SET_CURRENT_QUESTIONNAIRE_INDEX: {
      return {
        ...state,
        currentQuestionnaireIndex: payload,
      }
    }

    case SET_SELECTED_DOCUMENT_LIST: {
      return {
        ...state,
        selectedDocumentList: payload,
      }
    }

    case RESET_LOCAL_FORM_DATA: {
      return {
        ...state,
        localFormData: payload,
      }
    }

    default:
      return state;
  }
}
